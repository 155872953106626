import { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import validator from "validator";
import RegisterModal from "../modal/RegisterModal";
import { useAuth } from "../../provider/authProvider";
import LogoLight from "../../assets/images/webmetic_logo_light.png";
import LogoDark from "../../assets/images/webmetic_logo_dark.png";
import analytics from "../../analytics";
import { useAuthMutations } from "@/hooks";

const decodeJWT = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const Login = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [darkMode, setDarkMode] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const { loginMutations } = useAuthMutations();

  const handleClick = useCallback(() => {
    analytics.track("register_modal_opened", {
      source: "login",
    });
    setIsModalOpen(true);
  }, []);
  useEffect(() => {
    if (state !== null) {
      // Handle any state passed to this component if needed
    }
  }, [state]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    setGeneralError("");

    if (!email) {
      setEmailError("Bitte geben Sie Ihre E-Mail-Adresse ein.");
      return;
    }

    if (!validator.isEmail(email)) {
      setEmailError("Bitte geben Sie eine gültige E-Mail-Adresse ein!");
      return;
    }

    if (!password) {
      setPasswordError("Bitte geben Sie Ihr Passwort ein.");
      return;
    }

    setLoadingView(true);

    try {
      const response = await loginMutations.mutateAsync({
        email: email,
        password: password,
      });
      setLoadingView(false);
      if (
        response.data.code === 200 &&
        response.data.data &&
        response.data.data.access_token
      ) {
        // Successful login
        const accessToken = response.data.data.access_token;
        const decodedToken = decodeJWT(accessToken);
        const accountId = decodedToken ? decodedToken.account_id : null;
        try {
          await signIn(accessToken);

          analytics.identify(accountId);
          analytics.track("login", {
            method: "email",
          });
          navigate("/dashboard");
        } catch (signInError) {
          console.error("Error during signIn:", signInError);
          setGeneralError(
            "Ein Fehler ist beim Anmelden aufgetreten. Bitte versuchen Sie es erneut.",
          );
          // Push login error event to dataLayer
          analytics.track("error", {
            error: "loggin_error",
            error_type: "sign_in_error",
            error_message: signInError.message || "Unknown sign-in error",
          });
        }
      } else if (response.data.error) {
        // Handle error responses
        handleErrorResponse(response.data);
        analytics.track("error", {
          error: "loggin_error",
          error_type: "authentication_failed",
          error_message: response.data.message || "Authentication failed",
        });
      } else {
        // Unexpected response structure
        console.error("Unexpected response structure:", response.data);
        setGeneralError(
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
        );
        // Push unexpected error event to dataLayer
        analytics.track("error", {
          error: "loggin_error",
          error_type: "unexpected_response",
          error_message: "Unexpected response structure from server",
        });
      }
    } catch (error) {
      setLoadingView(false);
      console.error("Error during login:", error);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        analytics.track("error", {
          error: "loggin_error",
          error_type: "server_error",
          error_message:
            error.response.data.message ||
            `HTTP error! status: ${error.response.status}`,
        });
      } else if (error.request) {
        // The request was made but no response was received
        analytics.track("error", {
          error: "loggin_error",
          error_type: "network_error",
          error_message: "No response received from server",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        analytics.track("error", {
          error: "loggin_error",
          error_type: "request_setup_error",
          error_message: error.message || "Error setting up the request",
        });
      }

      // Set the appropriate error message for the user
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setGeneralError(error.response.data.message);
      } else {
        setGeneralError(
          "Ein Fehler ist beim Senden der Anfrage aufgetreten. Bitte versuchen Sie es später erneut.",
        );
      }
    }
  };

  const handleErrorResponse = (errorData) => {
    const { code, message } = errorData;
    switch (code) {
      case 404:
        setEmailError("Kein Konto mit dieser E-Mail-Adresse gefunden.");
        break;
      case 401:
        setPasswordError("Falsches Passwort. Bitte versuchen Sie es erneut.");
        break;
      case 500:
        setGeneralError(
          `Ein Serverfehler ist aufgetreten. Bitte kontaktieren Sie uns (Fehlercode: #${code})`,
        );
        break;
      default:
        setGeneralError(
          message ||
            "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.",
        );
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const getCreativeGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Guten Morgen";
    if (hour < 18) return "Guten Tag";
    return "Guten Abend";
  };
  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="mx-auto flex flex-col items-center justify-center px-6 py-8 md:h-screen lg:py-0">
        <div className="mb-6 flex items-center">
          <Link to="/" className="flex items-center">
            <img
              src={LogoLight}
              className="mr-3 h-6 dark:hidden sm:h-9"
              alt="Webmetic Logo Light"
            />
            <img
              src={LogoDark}
              className="mr-3 hidden h-6 dark:block sm:h-9"
              alt="Webmetic Logo Dark"
            />
          </Link>
        </div>

        <div className="w-full rounded-lg bg-white shadow dark:border dark:border-gray-700 dark:bg-gray-800 sm:max-w-md md:mt-0 xl:p-0">
          <div className="p-6 sm:p-8">
            <h1 className="pb-4 text-xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-2xl">
              Login
            </h1>
            <form onSubmit={handleLogin}>
              <div>
                <label htmlFor="email" className="form-label">
                  Ihre E-Mail
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className={`form-input transition-all duration-200 ${
                      emailError
                        ? "form-input-error"
                        : isEmailValid
                          ? "form-input-success"
                          : ""
                    }`}
                    placeholder="name@firma.de"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setIsEmailValid(validator.isEmail(e.target.value));
                    }}
                  />
                </div>
                <div className="min-h-[20px]">
                  {emailError && (
                    <p className="form-message-error transition-all duration-200">
                      <span className="form-message-highlight">Fehler:</span>{" "}
                      {emailError}
                    </p>
                  )}
                  {isEmailValid && !emailError && (
                    <p className="form-message-success transition-all duration-200">
                      Das sieht nach einer gültigen E-Mail Addresse aus.
                    </p>
                  )}
                </div>
                <label htmlFor="password" className="form-label">
                  Passwort
                </label>
                <div className="relative mt-1">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className={`form-input transition-all duration-200 ${
                      passwordError
                        ? "form-input-error"
                        : isPasswordValid
                          ? "form-input-success"
                          : ""
                    }`}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setIsPasswordValid(e.target.value.length >= 8);
                    }}
                  />
                  <button
                    type="button"
                    className="absolute right-3 top-1/2 -translate-y-1/2 transform"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <svg
                        className="h-6 w-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="h-6 w-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                        />
                        <path
                          stroke="currentColor"
                          strokeWidth="2"
                          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                <div className="h-6 overflow-hidden">
                  {passwordError && (
                    <p className="form-message-error">
                      <span className="form-message-highlight">Fehler:</span>{" "}
                      {passwordError}
                    </p>
                  )}
                  {isPasswordValid && !passwordError && (
                    <p className="form-message-success">
                      Passwort erfüllt die Anforderungen.
                    </p>
                  )}
                </div>
              </div>
              {generalError && (
                <p className="text-sm text-red-500">{generalError}</p>
              )}
              <button
                type="submit"
                className="btn w-full"
                disabled={loadingView}
              >
                {loadingView ? (
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="me-3 inline h-4 w-4 animate-spin text-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  "Anmelden"
                )}
              </button>
            </form>
            <button
              onClick={handleClick}
              type="submit"
              className="btn-alt mt-2 w-full"
            >
              Registrieren
            </button>
            <Link
              to="/send_password"
              className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Passwort vergessen?
            </Link>
          </div>
        </div>
      </div>
      <RegisterModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        initialWebsite=""
      />
    </section>
  );
};

export default Login;
