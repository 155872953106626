import { Outlet } from "react-router-dom";

import clsx from "clsx";
import DateFilterDialog from "./components/date-filter-dialog";
import { useEffect } from "react";

import LogoLight from "@/assets/images/webmetic_logo_light.png";
import LogoDark from "@/assets/images/webmetic_logo_dark.png";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
// import SegmentsDrawer from "./components/segments-drawer.tsx";
import { useCompaniesFilterView } from "@/hooks/index.ts";
// import { SegmentCard } from "./components/segment-card.tsx";
import { GERMAN_LABELS } from "@/constants/germanLabels.ts";

function CompaniesFilterView() {
  const {
    user,
    list,
    params,
    dateRange,
    setDateRange,
    resetSelectedCompany,
    handleSelectDateRangeOption,
    handleUpdateSearchParams,
    selectedItem,
    // segmentsList,
    // selectedSegmentId,
    // editableSegment,
    // handleDeleteSegment,
    // handleSetSelectedSegmentId,
    // handleResetSelectedSegmentId,
    // handleSetEditableSegment,
    // handleResetEditableSegment,
  } = useCompaniesFilterView();

  useEffect(() => {
    document.documentElement.setAttribute("data-route", "dashboard");
    return () => document.documentElement.removeAttribute("data-route");
  }, []);

  return (
    <>
      <div className="hidden xl:fixed xl:inset-y-0 xl:flex xl:w-72 xl:flex-col">
        <div className="flex grow flex-col overflow-y-auto border-r bg-muted/40">
          <div className="sticky top-0 flex h-16 shrink-0 items-center border-b bg-background px-6">
            <img
              src={LogoLight}
              className="mr-3 mt-1 h-7 dark:hidden sm:h-9"
              alt="Webmetic Logo"
            />
            <img
              src={LogoDark}
              className="mr-3 mt-1 hidden h-7 dark:block sm:h-9"
              alt="Webmetic Logo"
            />
          </div>
          {!!user?.website.length && (
            <div className="sticky top-16 border-b bg-background p-2">
              <Select
                value={params.domain}
                onValueChange={resetSelectedCompany}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Domain" />
                </SelectTrigger>
                <SelectContent>
                  {user.website.map((v) => (
                    <SelectItem key={v} value={v}>
                      {v}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
          <nav className="flex flex-1 flex-col px-6">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  {list.map((item) => (
                    <li key={item.label}>
                      <button
                        type="button"
                        onClick={handleSelectDateRangeOption(item)}
                        className={clsx(
                          selectedItem?.label === item.label
                            ? "bg-muted text-primary hover:text-primary"
                            : "text-muted-foreground hover:text-primary",
                          "group flex w-full justify-between gap-x-3 rounded-md px-2 py-1 text-sm font-semibold leading-6 transition-all",
                        )}
                      >
                        <span className="truncate capitalize">
                          {GERMAN_LABELS[item.label] || item.label}
                        </span>
                        <span className="inline-flex min-h-6 min-w-6 shrink-0 items-center justify-center rounded-lg border px-2 py-1 text-xs font-medium text-muted-foreground group-hover:text-primary">
                          {item.companies}
                        </span>
                      </button>
                    </li>
                  ))}
                  <li>
                    <DateFilterDialog
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      onSubmit={handleUpdateSearchParams}
                      trigger={
                        <button
                          type="button"
                          className={clsx(
                            "text-muted-foreground hover:bg-muted hover:text-primary",
                            "group flex w-full flex-col gap-x-3 rounded-md border p-2 text-sm font-semibold leading-6",
                          )}
                        >
                          <span className="capitalize">Zeitraum festlegen</span>
                          <span className="text-start text-[0.625rem] font-medium">
                            Datum manuell auswählen
                          </span>
                        </button>
                      }
                    />
                  </li>
                </ul>
              </li>
              {/* <li>
                <div className="flex flex-col gap-2 pb-2 pt-4">
                  <span className="text-sm font-semibold leading-6 text-primary">
                    Segmente
                  </span>
                  <div className="flex flex-col gap-2">
                    {segmentsList?.map((segment) => (
                      <SegmentCard
                        key={segment._id}
                        segment={segment}
                        isSelected={segment._id === selectedSegmentId}
                        onDelete={handleDeleteSegment}
                        handleSetEditableSegment={handleSetEditableSegment}
                        handleSetSelectedSegmentId={handleSetSelectedSegmentId}
                      />
                    ))}
                  </div>
                </div>
                <ul role="list" className="-mx-2 my-2 space-y-1">
                  <li>
                    <SegmentsDrawer
                      editableSegment={editableSegment}
                      handleResetEditableSegment={handleResetEditableSegment}
                      handleResetSelectedSegmentId={
                        handleResetSelectedSegmentId
                      }
                      trigger={
                        <button
                          type="button"
                          onClick={() => handleResetSelectedSegmentId()}
                          className={clsx(
                            "text-muted-foreground hover:bg-muted hover:text-primary",
                            "group flex w-full flex-col gap-x-3 rounded-md border p-2 text-sm font-semibold leading-6",
                          )}
                        >
                          <span className="capitalize">New Segment</span>
                          <span className="text-start text-[0.625rem] font-medium">
                            Create your advanced companies filter
                          </span>
                        </button>
                      }
                    />
                  </li>
                </ul>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default CompaniesFilterView;
