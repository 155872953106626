import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
const AGB = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <section className="bg-white py-8 dark:bg-gray-900 lg:py-24">
          <div className="format dark:format-invert mx-auto max-w-4xl px-4 lg:px-4">
            <h1 className="h2-style mb-2">AGB</h1>
            <p className="mb-6 text-lg text-gray-600 dark:text-gray-400 lg:text-lg">
              Zuletzt aktualisiert: 08.10.2024
            </p>
            <p className="h6-style my-4 mb-2 mt-4">1. Anwendungsbereich</p>
            <p className="dark:text-white">
              Diese Bedingungen regeln die Nutzung von Webmetic, einem
              innovativen Webanalyse-Service der Intent GmbH. Sie gelten für
              alle Geschäftsbeziehungen zwischen der Intent GmbH und den Nutzern
              von Webmetic, sowohl für aktuelle als auch für zukünftige
              Geschäfte. Durch die Nutzung von Webmetic erklären sich die Nutzer
              mit diesen Bedingungen einverstanden.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">2. Dienstleistungen</p>
            <p className="dark:text-white">
              Webmetic bietet umfassende und detaillierte Analysefunktionen für
              Websites durch fortschrittliche Tracking-Scripts. Diese Dienste
              sind über einen speziell gesicherten Bereich zugänglich, der den
              Nutzern eine effiziente und zuverlässige Nutzung ermöglicht.
              Webmetic hilft Unternehmen dabei, wertvolle Einblicke in das
              Nutzerverhalten zu gewinnen und somit ihre Online-Strategien zu
              optimieren.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">
              3. Registrierung und Sicherheit
            </p>
            <p className="dark:text-white">
              Die Registrierung ist ein notwendiger Schritt, um Zugang zu den
              vielfältigen Funktionen von Webmetic zu erhalten. Nutzer müssen
              bei der Registrierung relevante Unternehmens- und Kontaktdaten
              sowie ihre Zahlungsinformationen angeben. Nutzer sind für die
              Sicherheit und Vertraulichkeit ihres Passworts verantwortlich und
              haften für jeglichen Missbrauch. Im Falle eines Verdachts auf
              Missbrauch sind die Nutzer verpflichtet, Intent GmbH unverzüglich
              zu informieren.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">4. Verfügbarkeit</p>
            <p className="dark:text-white">
              Intent GmbH zielt darauf ab, Webmetic mit einer hohen
              Verfügbarkeit von 99.9% im Monatsdurchschnitt anzubieten.
              Ausgenommen von dieser Verfügbarkeitsgarantie sind Fälle von
              höherer Gewalt, Fehlbedienungen durch den Kunden und Zeiten
              geplanter Wartungsarbeiten. Intent GmbH wird alle zumutbaren
              Maßnahmen ergreifen, um die Verfügbarkeit und Zuverlässigkeit von
              Webmetic sicherzustellen.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">
              5. Support und Reaktionszeiten
            </p>
            <p className="dark:text-white">
              Intent GmbH stellt einen umfassenden Support zur Verfügung, um bei
              jeglichen Problemen mit Webmetic schnell und effizient helfen zu
              können. Kunden müssen Probleme unverzüglich melden, damit sie
              schnellstmöglich behoben werden können. Der Support ist werktags
              erreichbar, und Intent GmbH bemüht sich, innerhalb eines
              Arbeitstages auf Supportanfragen zu reagieren. Dieser Support
              umfasst technische Unterstützung sowie Beratung zur optimalen
              Nutzung der Analysefunktionen.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">6. Urheberrechte</p>
            <p className="dark:text-white">
              Die von Webmetic generierten Analyseergebnisse sind ausschließlich
              für die interne Verwendung durch den Nutzer bestimmt. Jegliche
              Weitergabe dieser Daten oder der Software an Dritte, sowie
              jegliche Form der Vervielfältigung außerhalb des vertraglich
              vereinbarten Rahmens, ist strikt untersagt. Webmetic und alle
              damit verbundenen Inhalte sind urheberrechtlich geschützt.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">7. Vergütung</p>
            <p className="dark:text-white">
              Intent GmbH bietet eine 30-tägige kostenfreie Testversion von
              Webmetic an. Nach Ablauf dieser Frist können Nutzer aus
              verschiedenen Lizenzmodellen wählen. Die Zahlung der monatlichen
              Gebühren ist jeweils zu Beginn des Abrechnungsmonats fällig und
              kann über diverse Zahlungsmethoden erfolgen. Bei Zahlungsverzug
              behält sich Intent GmbH das Recht vor, den Zugang zu den
              Dienstleistungen zu sperren.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">8. Haftung</p>
            <p className="dark:text-white">
              Die Haftung der Intent GmbH ist auf Fälle von Vorsatz oder grober
              Fahrlässigkeit beschränkt. In diesen Fällen haftet Intent GmbH für
              typischerweise vorhersehbare Schäden. In allen anderen Fällen ist
              die Haftung ausgeschlossen, sofern nicht durch gesetzliche
              Bestimmungen anders geregelt. Diese Haftungsbeschränkung umfasst
              auch indirekte Schäden und entgangene Gewinne.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">9. Rechte Dritter</p>
            <p className="dark:text-white">
              Intent GmbH garantiert, dass die Nutzung von Webmetic keine Rechte
              Dritter verletzt. Sollten Dritte dennoch Rechte geltend machen,
              verpflichtet sich der Nutzer, Intent GmbH unverzüglich zu
              informieren und bei der Klärung der Angelegenheit zu unterstützen.
              Intent GmbH wird alle notwendigen Maßnahmen ergreifen, um eine
              Verletzung der Rechte Dritter zu verhindern.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">10. Datenschutz</p>
            <p className="dark:text-white">
              Webmetic entspricht allen relevanten Datenschutzgesetzen. Nutzer
              sind selbst verantwortlich für die datenschutzkonforme Nutzung der
              Daten und müssen bei der Erhebung personenbezogener Daten die
              notwendigen Einwilligungen einholen. Intent GmbH verpflichtet
              sich, die Daten der Nutzer sicher zu speichern und nur im Rahmen
              der gesetzlichen Bestimmungen zu verwenden.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">
              11. Änderungen der Bedingungen
            </p>
            <p className="dark:text-white">
              Intent GmbH behält sich das Recht vor, die Bedingungen und die
              Vergütung für Webmetic zu ändern. Änderungen werden den Nutzern
              schriftlich mitgeteilt und gelten als akzeptiert, wenn nicht
              innerhalb eines Monats nach Erhalt Widerspruch eingelegt wird. Bei
              Widerspruch kann Intent GmbH den Vertrag mit einer einmonatigen
              Frist kündigen. Änderungen treten einen Monat nach Mitteilung in
              Kraft.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">12. Zahlungen</p>
            <p className="dark:text-white">
              Rechnungen der Intent GmbH sind unmittelbar nach Erhalt zu
              begleichen, ohne jegliche Abzüge. Bei Zahlungsverzug fallen
              Verzugszinsen in Höhe von 5% über dem Basiszins der EZB an, sofern
              kein höherer Schaden nachgewiesen wird. Intent GmbH behält sich
              das Recht vor, Vorauskasse zu verlangen und erst nach
              vollständigem Zahlungseingang die Leistungen zu erbringen.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">13. Referenzrecht</p>
            <p className="dark:text-white">
              Mit der Nutzung erklärt sich der Kunde damit einverstanden, als
              Referenzkunde der Intent GmbH genannt zu werden. Intent GmbH darf
              das Logo des Auftraggebers für Marketingzwecke verwenden. Diese
              Zustimmung kann vom Kunden jederzeit widerrufen werden, indem er
              Intent GmbH schriftlich informiert.
            </p>
            <p className="h6-style my-4 mb-2 mt-4">
              14. Auftragsdatenverarbeitung
            </p>
            <p className="dark:text-white">
              Die Intent GmbH verarbeitet die im Rahmen der Nutzung von Webmetic
              erhobenen Daten im Auftrag des Kunden gemäß Art. 28 DSGVO. Hierzu
              wird mit jedem Kunden ein Auftragsdatenverarbeitungsvertrag
              abgeschlossen, der alle wesentlichen Aspekte der
              Auftragsverarbeitung regelt. Der Vertrag umfasst insbesondere:
            </p>
            <ul className="my-2 ml-6 list-disc dark:text-white">
              <li>den Gegenstand und die Dauer der Verarbeitung,</li>
              <li>die Art, den Umfang und den Zweck der Datenerhebung,</li>
              <li>
                die Umsetzung technischer und organisatorischer Maßnahmen zur
                Sicherung der Daten,
              </li>
              <li>
                Regelungen zur Berichtigung, Löschung und Sperrung von Daten,
              </li>
              <li>
                die Voraussetzungen für die Begründung von
                Unterauftragsverhältnissen,
              </li>
              <li>die Kontroll- und Weisungsrechte des Kunden.</li>
            </ul>
            <p className="dark:text-white">
              Die Intent GmbH stellt sicher, dass alle Anforderungen der DSGVO
              sowie weiterer anwendbarer Datenschutzgesetze eingehalten werden.
              Der Vertrag wird dem Kunden bei Vertragsabschluss zur Verfügung
              gestellt und ist integraler Bestandteil der Nutzung von Webmetic.
            </p>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default AGB;
