import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import HeaderDropdownMenu from "./components/header/DropdownMenu";
import { useState, useMemo, useEffect } from "react";
import CompanyListItem from "@/routes/companies/companies-list/components/companyListItem.tsx";
import { InView } from "react-intersection-observer";
import CompanyListItemSkeleton from "@/routes/companies/companies-list/components/companyListItemSkeleton.tsx";
import { useAsync } from "react-async-hook";
import { useSuspenseQuery } from "@tanstack/react-query";
import { companiesFilterQueryOptions } from "../companies-filter/companies-filter.loader.ts";
import dataAnalysis from "../../../assets/images/data_analysis.svg";

import TrackingCodeDrawer from "@/routes/account/TrackingCodeDrawer";
import ApiDetailsDrawer from "@/routes/account/ApiDetailsDrawer";
import AVContractDrawer from "@/routes/account/AVContractDrawer";
import CompanyProfileDrawer from "@/routes/account/CompanyProfileDrawer";
import ProfileDrawer from "@/routes/account/ProfileDrawer";
import SupportDrawer from "@/routes/account/SupportDrawer";
import UsersDrawer from "@/routes/account/UsersDrawer";
import WebsitesDrawer from "@/routes/account/WebsitesDrawer";
import { CompaniesListSearch } from "./components/companies-list-search.tsx";
import { useCompanyListQuery, useSearchCompanyQuery } from "@/hooks/index.ts";
import { CompanyMode } from "@/types/company.ts";
import { Loader } from "@/components/index.ts";

function CompaniesListView() {
  const params = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const [searchDropdownValue, setSearchDropdownValue] = useState(
    CompanyMode.company,
  );

  console.log(params);

  const hasSearchValue =
    searchValue.trim().length || searchDropdownValue !== "company";

  const { data: filterList } = useSuspenseQuery(
    companiesFilterQueryOptions(params.domain!),
  );

  const {
    data: companiesList,
    fetchNextPage: fetchNextCompanyPage,
    hasNextPage: hasNextCompanyPage,
    refetch: refetchCompany,
    isLoading: isCompanyLoading,
  } = useCompanyListQuery(
    {
      domain: params.domain!,
      from_date: searchParams.get("from_date"),
      to_date: searchParams.get("to_date"),
    },
    !hasSearchValue,
  );

  const {
    data: searchCompaniesList,
    fetchNextPage: fetchNextSearchPage,
    hasNextPage: hasNextSearchPage,
    refetch: refetchSearch,
    isLoading: isSearchLoading,
  } = useSearchCompanyQuery(
    {
      domain: params.domain!,
      q: searchValue,
      mode: searchDropdownValue as CompanyMode,
      from_date: searchParams.get("from_date"),
      to_date: searchParams.get("to_date"),
    },
    !!hasSearchValue,
  );

  // State for all drawers
  const [openDrawer, setOpenDrawer] = useState<string | null>(null);

  const onSearch = (val: string) => {
    setSearchValue(val);
  };

  const onChangeSearchDropdownValue = (val: string) => {
    setSearchDropdownValue(val as CompanyMode);
  };

  useEffect(() => {
    const menuParam = searchParams.get("menu");
    if (menuParam) {
      setOpenDrawer(menuParam);
      // Remove the menu parameter from the URL
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("menu");
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  const openDrawerHandler = (drawerName: string) => {
    setOpenDrawer(drawerName);
  };

  const closeDrawerHandler = () => {
    setOpenDrawer(null);
  };

  useAsync(async () => {
    if (hasSearchValue) {
      return await refetchSearch();
    }
    return await refetchCompany();
  }, [searchParams]);

  const flatList = useMemo(() => {
    return hasSearchValue
      ? searchCompaniesList?.pages?.flatMap((p) => p.results || []) || []
      : companiesList?.pages?.flatMap((p) => p.result || []) || [];
  }, [hasSearchValue, companiesList, searchCompaniesList]);

  const fetchNextPage = hasSearchValue
    ? fetchNextSearchPage
    : fetchNextCompanyPage;

  const hasNextPage = hasSearchValue ? hasNextSearchPage : hasNextCompanyPage;

  const allTimeOption = filterList?.find((item) => item.label === "all_time");
  const hasAllTimeCompanies = allTimeOption && allTimeOption.companies > 0;

  useEffect(() => {
    if (!params?.company_id && flatList.length > 0) {
      navigate({
        pathname: `${flatList[0].company_id}`,
        search: location.search,
      });
    }
  }, [flatList, params?.company_id, navigate]);

  return (
    <div className="flex h-full flex-col xl:pl-72">
      <div className="top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b bg-background px-4 sm:px-6 lg:px-8">
        <h1 className="text-xl font-bold">
          {hasAllTimeCompanies
            ? "Identifizierte Unternehmen"
            : "Willkommen bei Webmetic"}
        </h1>
        <div className="w-full flex-1"></div>
        <HeaderDropdownMenu />
      </div>
      {hasAllTimeCompanies ? (
        <div className="flex flex-1">
          <aside className="w-full lg:w-96 lg:overflow-y-auto lg:border-r xl:left-72">
            <div className="border-b bg-background p-2">
              <CompaniesListSearch
                searchValue={searchValue}
                onChangeSearch={onSearch}
                dropDownValue={searchDropdownValue}
                onChangeDropdownValue={onChangeSearchDropdownValue}
              />
            </div>

            <div // _-_57px after 100% for search bar
              style={{
                height: "calc(100dvh - 115px)",
              }}
              className="flex grow flex-col gap-3 overflow-scroll bg-[#f0f2f5] pl-4 pr-3 pt-3"
              id="company-list"
            >
              {(isSearchLoading || isCompanyLoading) && <Loader />}

              {flatList.map((company) => (
                <CompanyListItem key={company.company_id} company={company} />
              ))}
              <InView
                onChange={(inView) => {
                  if (inView && hasNextPage) fetchNextPage();
                }}
                disabled={!hasNextPage}
              >
                {hasNextPage && <CompanyListItemSkeleton />}
              </InView>
            </div>
          </aside>
          <main className="flex-1">
            <Outlet />
          </main>
        </div>
      ) : (
        <div className="flex h-[calc(100vh-4rem)] items-center justify-center">
          <div className="container mx-auto px-4 lg:px-6">
            <div className="flex flex-col items-center lg:flex-row">
              {/* Text content (1/3) */}
              <div className="ml-8 flex w-full flex-col items-center justify-center text-center lg:ml-16 lg:w-1/3">
                <svg
                  className="mb-4 h-12 w-12 text-green-600 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>

                <h1 className="mb-2 text-2xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
                  Konto erfolgreich eingerichtet
                </h1>
                <p className="mb-4 font-light text-gray-500 dark:text-gray-400 md:mb-6">
                  Die ersten Unternehmensdaten werden sichtbar, sobald Sie den
                  Tracking-Code eingebunden haben.
                </p>
                <button
                  onClick={() => openDrawerHandler("tracking_code")}
                  className="btn"
                >
                  Tracking Code anzeigen
                </button>
              </div>
              {/* Image (2/3) */}
              <div className="w-full lg:w-2/3">
                <img
                  className="w-full rounded-lg"
                  src={dataAnalysis}
                  alt="Leads identifizieren"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <TrackingCodeDrawer
        open={openDrawer === "tracking_code"}
        onClose={closeDrawerHandler}
      />
      <ApiDetailsDrawer
        open={openDrawer === "api_details"}
        onClose={closeDrawerHandler}
      />
      <AVContractDrawer
        open={openDrawer === "av_contract"}
        onClose={closeDrawerHandler}
      />
      <CompanyProfileDrawer
        open={openDrawer === "company_profile"}
        onClose={closeDrawerHandler}
      />
      <ProfileDrawer
        open={openDrawer === "profile"}
        onClose={closeDrawerHandler}
      />
      <SupportDrawer
        open={openDrawer === "support"}
        onClose={closeDrawerHandler}
      />
      <UsersDrawer open={openDrawer === "users"} onClose={closeDrawerHandler} />
      <WebsitesDrawer
        open={openDrawer === "websites"}
        onClose={closeDrawerHandler}
      />
    </div>
  );
}

export default CompaniesListView;
